import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";
import r360Video from "components/cameras/mp4/r360-fisheye-animation.hevc.mp4";
import r360VideoBackup from "components/cameras/mp4/backups/r360-fisheye-animation.mp4";

export default function FisheyeCameras() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "Fisheye cameras",
        title: "Cover All Angles with Seamless 360˚ Views",
        description:
          "Get panoramic coverage with cloud-based fisheye security cameras. Modernize your physical security with remote access, onboard analytics, and enterprise-grade performance and reliability.",
        image: data.headerImage,
        button1: {
          text: "View R360",
          path: "/cameras/fisheye-cameras/r360",
        },
        button2: {
          text: "Compare Cameras",
          path: "/cameras/compare-cameras/",
        },
      },
      benefitsSection: {
        title: "Eliminate Blind Spots with Panoramic Coverage",
        items: [
          {
            image: data.benefit1,
            title: "Cover All Angles",
            description:
              "Say goodbye to blind spots. With real-time de-warping of 360° footage, you can monitor large areas from multiple angles and ensure nothing is out of frame or missed. ",
          },
          {
            image: data.benefit2,
            title: "Seamlessly Monitor Large Areas",
            description:
              "From stadiums to auditoriums to showroom floors, fisheye cameras provide seamless coverage of large, open spaces. Get the context and visibility you need with just one camera.",
          },
          {
            image: data.benefit3,
            title: "Jump Into the Frame",
            description:
              "Take full advantage of the 360° viewing experience with Immersive View. Dive into the video and move around within the scene for unparalleled situational awareness.",
          },
        ],
      },
      specSection: {
        title: "Gain Immersive Coverage",
        image: data.specImage,
        specs: [
          { icon: data.icon1, title: "12MP", subTitle: "Image Sensor" },
          {
            icon: data.icon2,
            title: "Compact",
            subTitle: "130mm x 58mm",
          },
          {
            icon: data.icon3,
            title: "Up to 60 Days",
            subTitle: "On-Camera Storage",
          },
          {
            icon: data.icon4,
            title: "IP66",
            subTitle: "Weather Resistance",
          },
          {
            icon: data.icon5,
            title: "IK10",
            subTitle: "Impact Resistance",
          },
          {
            icon: data.icon6,
            title: "Live Dewarping",
            subTitle: "Video",
          },
        ],
      },
      exploreSection: {
        cards: [
          {
            image: data.recommend1,
            name: "Dome Cameras",
            description:
              "Modern enterprise performance with edge-based analytics ",
            link: "/cameras/dome-cameras/",
            flex: 3,
          },
          {
            image: data.recommend2,
            name: "Bullet Cameras",
            description: "Ruggedized form-factor with high-resolution video",
            link: "/cameras/bullet-cameras/",
            flex: 3,
          },
          {
            image: data.recommend3,
            name: "Multisensor Cameras",
            description: "One powerful camera for complete coverage",
            link: "/cameras/multisensor-cameras/",
            flex: 3,
          },
        ],
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus Fisheye Cameras - Enterprise Video Surveillance</title>
          <meta
            name="description"
            content="Learn about Rhombus' fisheye security cameras for commercial businesses, education, healthcare, real estate, and more."
          />
        </Helmet>
        <DevicePageTemplate
          data={pageData}
          video={r360Video}
          videoBackup={r360VideoBackup}
          deviceType="Camera"
          recommend
          reverse
          image={data.fisheye}
        />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/fisheye-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device3: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device4: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device5: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/cover-all-angles-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/monitor-large-areas-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/arial-view-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/spec-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(relativePath: { eq: "components/common/icons/sensor.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/common/icons/dimensions.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(relativePath: { eq: "components/common/icons/servers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/common/icons/weather.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(relativePath: { eq: "components/common/icons/impact.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(
        relativePath: { eq: "components/common/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImageMobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      recommend1: file(
        relativePath: { eq: "components/cameras/img/dome-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend2: file(
        relativePath: { eq: "components/cameras/img/bullet-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend3: file(
        relativePath: { eq: "components/cameras/img/r600-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      fisheye: file(
        relativePath: { eq: "components/cameras/img/360-ceiling-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
